import { Title } from 'components/title/title';
import { useIsSchoolPostReset, useSchoolInInterimState } from 'hooks/school-calendar';
import moment from 'moment';
import { useUser } from 'queries/session';
import { TeacherContent } from 'views/teacher/components/teacher-content';
import { HandInSummary } from 'views/teacher/home-view/hand-in-summary/hand-in-summary';

import { TrainingContext } from '../teacher-training-view/training-context';
import { GettingStartedWidget } from './getting-started-widget/getting-started-widget';
import styles from './home-view.module.css';
import { InterimWidget } from './interim-widget/interim-widget';
import { PostResetWidget } from './post-reset-widget/post-reset-widget';
import { PraiseStats } from './praise-stats/praise-stats';
import { TrainingDashboardWidget } from './training-dashboard-widget/training-dashboard-widget';

export const HomeView = () => {
  const user = useUser();
  const currentHour = moment().hour();
  const hourText = currentHour < 12 ? 'morning' : currentHour < 18 ? 'afternoon' : 'evening';
  const isInterim = useSchoolInInterimState();
  const isSchoolPostReset = useIsSchoolPostReset();

  return (
    <TeacherContent narrow={true}>
      <Title>Home</Title>
      <h2>
        Home{' '}
        <span className={styles.WelcomeMessage}>
          Good {hourText}, {user?.firstName}
        </span>
      </h2>

      <div className={styles.Widgets}>
        {isInterim && <InterimWidget />}
        {isSchoolPostReset && <PostResetWidget />}
        <GettingStartedWidget />
        <TrainingContext>
          <TrainingDashboardWidget />
        </TrainingContext>
      </div>

      {<PraiseStats />}

      {!isInterim && <HandInSummary />}
    </TeacherContent>
  );
};
