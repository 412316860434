import classNames from 'classnames';
import React from 'react';

import styles from './ProgressWheel.module.css';

interface WheelSize {
  barWidth: number;
  outerCircleRadius: number;
}

interface ProgressWheelProps {
  completed: number;
  total: number;
  wheelSize?: WheelSize;
  className?: string;
  asPercentage?: boolean;
  hideProgressText?: boolean;
  classes?: {
    innerCircle?: string;
  };
}

const DEFAULT_WHEEL_SIZE = {
  barWidth: 14,
  outerCircleRadius: 60,
};

export const ProgressWheel = ({
  completed,
  total,
  wheelSize = DEFAULT_WHEEL_SIZE,
  asPercentage,
  hideProgressText,
  classes,
}: ProgressWheelProps) => {
  let percentage: number;
  if (total === 0) {
    percentage = 100;
  } else {
    percentage = (completed / total) * 100;
  }
  // This progress wheel is made up of 3 circles.
  // 1. The outer circle creates the track for the progress to fill (fill).
  // 2. The progress circle creates the progress bar (stroke only).
  // 3. The inner circle creates the space in the middle for the text (fill).
  const { barWidth, outerCircleRadius } = wheelSize;
  const progressCircleRadius = outerCircleRadius - barWidth / 2;
  const innerCircleRadius = outerCircleRadius - barWidth;
  const progressCircleCircumference = Math.PI * 2 * progressCircleRadius;
  const progressBarOffset =
    progressCircleCircumference - (progressCircleCircumference * percentage) / 100;

  // Annoyingly, we need access to the various sizes in JS as well as CSS so here we create CSS
  // variables so that the CSS has access to them.
  const cssVars = {
    ['--outer-circle-radius']: `${outerCircleRadius}px`,
    ['--bar-width']: `${barWidth}px`,
    ['--progress-circle-circumference']: `${progressCircleCircumference}px`,
  } as React.CSSProperties;
  return (
    <div className={styles.ProgressContainer}>
      <svg style={cssVars} className={styles.SVG}>
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" className={styles.GradientStart} />
            <stop offset="100%" className={styles.GradientStop} />
          </linearGradient>
        </defs>
        <circle
          className={styles.CircleOuter}
          cx={outerCircleRadius}
          cy={outerCircleRadius}
          r={outerCircleRadius}
        />
        <circle
          className={styles.CircleProgress}
          style={{
            ['strokeDashoffset']: `${progressBarOffset}`,
          }}
          cx={outerCircleRadius}
          cy={outerCircleRadius}
          r={progressCircleRadius}
          stroke="url(#gradient)"
        />
        <circle
          className={classNames(styles.CircleInner, classes && classes.innerCircle)}
          cx={outerCircleRadius}
          cy={outerCircleRadius}
          r={innerCircleRadius}
        />
      </svg>
      {!hideProgressText && (
        <div className={styles.ProgressText}>
          {asPercentage ? (
            `${Math.round(percentage)}%`
          ) : (
            <>
              {completed}/{total}
            </>
          )}
        </div>
      )}
    </div>
  );
};
