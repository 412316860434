import { faChevronRight, faRocket, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressWheel } from '@sparx/sparx-design/components';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { Button } from 'components/buttons';
import { Link } from 'components/link';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useUser } from 'queries/session';
import { Suspense, useState } from 'react';
import { View } from 'views';
import {
  useGettingStartedHomepageBanner,
  useGettingStartedProgress,
} from 'views/teacher/new-year-setup-view/hooks';
import { SuspenseStaffContextProvider } from 'views/teacher/staff-view/staff-manager-context';
import { TrainingContext } from 'views/teacher/teacher-training-view/training-context';
import { pathForView } from 'views/views';

import { clickedDismiss } from '../post-reset-widget/events';
import { clickedButton } from '../training-dashboard-widget/events';
import styles from './getting-started-widget.module.css';

const GettingStartedProgress = () => {
  const { isGettingStarted, isLoading: bannerLoading, isError } = useGettingStartedHomepageBanner();
  const user = useUser();
  const localStorageKey = () => `dismiss-getting-started-widget-${user?.userId}`;
  const [isDismissed, setIsDismissed] = useState(
    localStorage.getItem(localStorageKey()) === 'true',
  );

  const progress = useGettingStartedProgress();

  const isLoading = bannerLoading || progress.isLoading;

  const allStepsComplete = !isLoading && progress.completed === progress.total;

  const isDismissable = !isLoading && allStepsComplete;

  if (isDismissed || !user) return null;

  if (!isGettingStarted || isError) {
    return null;
  }

  const onDismiss = () => {
    localStorage.setItem(localStorageKey(), 'true');
    setIsDismissed(true);
  };

  return (
    <div className={styles.Banner}>
      <h3 className={styles.Label}>
        <FontAwesomeIcon icon={faRocket} /> Getting started
      </h3>
      <div className={styles.Card}>
        <div className={styles.Header}>
          Welcome to Sparx Reader!
          {isDismissable && (
            <Tooltip content="Dismiss this widget">
              <div className={styles.DismissButtonContainer}>
                <Button
                  className={styles.DismissButton}
                  analyticsEvent={clickedDismiss()}
                  onClick={onDismiss}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
        <div className={styles.Content}>
          <div className={styles.TextColumn}>
            <p className={styles.TextBold}>
              We&apos;re excited to see what your students can achieve with Sparx Reader!
            </p>
            {(isLoading || !allStepsComplete) && (
              <p className={styles.Text}>Let&apos;s make sure you have everything in place!</p>
            )}
            {!isLoading && allStepsComplete && (
              <p className={styles.Text}>You&apos;re all set up and ready to go!</p>
            )}
            {!allStepsComplete && (
              <Link to={pathForView(View.GettingStarted)} analyticsEvent={clickedButton()}>
                <Button
                  variant="inverse"
                  analyticsEvent={undefined}
                  rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  Follow our getting started guide
                </Button>
              </Link>
            )}
          </div>
          <div className={styles.ProgressColumn}>
            {isLoading ? (
              <LoadingSpinner size="lg" />
            ) : (
              <ProgressWheel completed={progress.completed} total={progress.total} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const GettingStartedWidget = () => {
  return (
    <Suspense fallback={undefined}>
      <SuspenseStaffContextProvider>
        <TrainingContext>
          <GettingStartedProgress />
        </TrainingContext>
      </SuspenseStaffContextProvider>
    </Suspense>
  );
};
