import { captureMessage } from '@sentry/react';
import {
  RolloverState_ProductRolloverState_RolloverType,
  SchoolYear,
} from '@sparx/api/apis/sparx/school/calendar/v4/calendar';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { add, addDays, isBefore, isFuture } from 'date-fns';
import { useEnglishStudentGroups } from 'queries/management';
import { useSchoolCalendar } from 'queries/school-calendar';
import { timestampToDate } from 'utils/time';

export const useCurrentSchoolYear = (opts?: { enabled?: boolean }) =>
  useSchoolCalendar({ select: calendar => calendar.currentYear, ...opts });

export const useSchoolInInterimState = (enabled = true) => {
  const { data: schoolYear } = useCurrentSchoolYear({ enabled });
  return readerIsInInterim(schoolYear);
};

type schoolRolloverCompleteState = {
  isSchoolRolloverComplete: boolean;
  schoolRolloverCompleteTime: Date | undefined;
  shouldDisplayRolloverCompleteBanner: boolean;
};

export const useSchoolRolloverCompleteState = (): schoolRolloverCompleteState => {
  const { data: schoolYear } = useCurrentSchoolYear();
  const { data: groups = [] } = useEnglishStudentGroups();

  const time = schoolYear?.rolloverState?.readerRolloverState?.rolloverCompleteTime;
  const date = timestampToDate(time);
  const shouldDisplayRolloverCompleteBanner =
    Boolean(date && isFuture(addDays(date, 5))) && groups.length === 0;

  return {
    isSchoolRolloverComplete: Boolean(time),
    schoolRolloverCompleteTime: date,
    shouldDisplayRolloverCompleteBanner,
  };
};

const POST_RESET_THRESHOLD_DAYS = 30;
export const useIsSchoolPostReset = () => {
  const { data: schoolYear } = useCurrentSchoolYear();

  const timestamp = schoolYear?.rolloverState?.readerRolloverState?.rolloverCompleteTime;

  if (!timestamp) {
    return false;
  }

  return isBefore(
    Timestamp.toDate(timestamp),
    add(new Date(), { days: POST_RESET_THRESHOLD_DAYS }),
  );
};

const readerIsInInterim = (schoolYear?: SchoolYear) => {
  if (!schoolYear?.rolloverState) {
    return false;
  }
  const hasRolledOver = Boolean(schoolYear.rolloverState.schoolRolloverTime);
  const { readerRolloverState } = schoolYear.rolloverState;

  if (readerRolloverState && readerRolloverState.product !== Product.SPARX_READER) {
    // Bad data
    captureMessage(
      `Rollover state product mismatch for ${schoolYear.name}: product specified for readerRolloverState is ${readerRolloverState.product}`,
    );
    return false;
  }

  const hadReader =
    readerRolloverState &&
    readerRolloverState.rolloverType !==
      RolloverState_ProductRolloverState_RolloverType.ROLLOVER_TYPE_NONE;
  const isComplete = readerRolloverState?.rolloverCompleteTime !== undefined;

  // Sparx Reader is in the interim state if the rollover has occurred, Sparx Reader was involved
  // in the rollover and the rollover is not yet complete
  return Boolean(hasRolledOver && hadReader && !isComplete);
};

/**
 * Returns true if the school is in the interim state or the most recent rollover was manual
 */
export const useMostRecentRolloverWasManual = () => {
  const isInRolloverInterim = useSchoolInInterimState();
  const { data: schoolYear } = useCurrentSchoolYear();

  const isManual =
    schoolYear?.rolloverState?.readerRolloverState?.rolloverType ===
    RolloverState_ProductRolloverState_RolloverType.PARTIAL;

  return isInRolloverInterim || isManual;
};
