import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { redirectToLogin } from 'app/handle-err';
import { useIsUserOnboarded, useSession } from 'queries/session';
import { useGetSubscriptionDetails } from 'queries/subscriptions';
import { useLocation, useNavigate } from 'react-router-dom';
import { View } from 'views';

import { useSchoolSubscriptionBanner } from './teacher/components/school-subscription-banner/hook';

interface ViewOptions {
  path: string;
  backView?: View;
}

const Views: Record<View, ViewOptions> = {
  [View.Landing]: {
    path: '/',
  },
  [View.Task]: {
    path: '/task',
    backView: View.Library,
  },
  [View.Library]: {
    path: '/library',
  },
  [View.GoldReader]: {
    path: '/library/goldreader',
  },
  [View.Explore]: {
    path: '/library/explore',
  },
  [View.ChooseNewBook]: {
    path: '/library/explore/new',
  },
  [View.AddGoldReaderBook]: {
    path: '/library/add',
  },
  [View.League]: {
    path: '/library/league',
  },
  [View.VocabLanding]: {
    path: '/library/vocab',
  },
  [View.Vocab]: {
    path: '/vocab',
    backView: View.Library,
  },
  [View.Onboarding]: {
    path: '/onboarding',
  },

  // Admin view
  [View.EQM]: {
    path: '/admin',
  },
  [View.EQMCustomBooks]: {
    path: '/admin/custombooks',
  },
  [View.EQMCustomBookQuestions]: {
    path: '/admin/custombooks/questions',
  },

  // Teacher views
  [View.Teacher]: {
    path: '/teacher',
  },
  [View.HandIn]: {
    path: '/teacher/classes/homework',
  },
  [View.TeacherCPD]: {
    path: '/teacher/reading-matters',
  },
  [View.TeacherReports]: {
    path: '/teacher/reports',
  },
  [View.WhatsNew]: {
    path: '/teacher/whats-new',
  },
  [View.TeacherLibrary]: {
    path: '/teacher/library',
  },
  [View.StaffManagerList]: {
    path: '/teacher/staff',
  },
  [View.StaffKeyContacts]: {
    path: '/teacher/staff/keycontacts',
  },
  [View.TeacherTraining]: {
    path: '/teacher/training',
  },
  [View.MISSyncImport]: {
    path: '/teacher/mis-sync-import',
  },
  [View.NewYearSetup]: {
    path: '/teacher/new-year-setup',
  },
  [View.NewYearSetupImportClasses]: {
    path: '/teacher/new-year-setup/import-classes-and-students',
  },
  [View.SchoolSubscription]: {
    path: '/teacher/school-settings/subscription',
  },
  [View.TeacherLeaderboard]: {
    path: '/teacher/leaderboard',
  },
  [View.Feedback]: {
    path: '/teacher/feedback',
  },
  [View.GettingStarted]: {
    path: '/teacher/getting-started',
  },
};

export interface ICurrentView {
  view: View;
  options: ViewOptions;
  popped: boolean;
}

export const useCurrentView = (): ICurrentView | undefined => {
  const location = useLocation();
  const path = location.pathname;
  const view = Object.entries(Views).find(([_, options]) => options.path === path);
  return view
    ? { view: view[0] as View, options: view[1], popped: location.state?.popped }
    : undefined;
};

export const pathForView = (view: View) => {
  return Views[view] ? Views[view].path : '';
};

export const subPathForView = (view: View, parent: View) => {
  const p = pathForView(view);
  let pp = pathForView(parent);
  if (!pp.endsWith('/')) {
    pp += '/';
  }
  if (p.startsWith(pp)) {
    return p.slice(pp.length);
  }
  // Don't allow
  return '';
};

export const useNavigateHomeForUser: () => (isManualNavigation: boolean) => void = () => {
  const navigate = useNavigate();
  const { data: session, isLoading: isSessionLoading } = useSession();
  const isOnboarded = useIsUserOnboarded();

  // Wait until the subscription details are loaded so we know whether the subcription banner is showing.
  const shouldLoadSubscriptionDetails = session?.user?.type === UserType.TEACHER;
  const { isLoading: isSubscriptionDetailsLoading } = useGetSubscriptionDetails({
    enabled: shouldLoadSubscriptionDetails,
  });
  const schoolSubscriptionBanner = useSchoolSubscriptionBanner(shouldLoadSubscriptionDetails);

  if (isSessionLoading || (isSubscriptionDetailsLoading && shouldLoadSubscriptionDetails)) {
    return () => undefined;
  }
  if (!session?.user) {
    return () => redirectToLogin();
  }
  if (session.user.type === UserType.TEACHER) {
    // Temporary change to force teachers to visit the subscriptions page when landing in the app. We redirect if the
    // subcription banner is showing.
    if (schoolSubscriptionBanner.show && schoolSubscriptionBanner.kind === 'TRIAL_ENDING') {
      return isManualNavigation => {
        // A manual navigation is when the user clicks a button to go home rather than being automatically redirected.
        if (isManualNavigation) {
          navigate(pathForView(View.Teacher), { replace: true });
        } else {
          navigate(pathForView(View.SchoolSubscription), { replace: true });
        }
      };
    }
    return () => navigate(pathForView(View.Teacher), { replace: true });
  }
  if (!isOnboarded) {
    return () => navigate(pathForView(View.Onboarding), { replace: true });
  }
  return () => navigate(pathForView(View.Library), { replace: true });
};

export const useIsInTeacherMode = () => {
  const location = useLocation();
  return location.pathname.startsWith('/teacher');
};
