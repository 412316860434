import { useCurrentTrainingState } from '@sparx/teacher-training';
import { useMemo } from 'react';

import { TRAINING_MODULE_ESSENTIAL, TRAINING_MODULE_USING_SPARX_READER } from '../module-specs';

export enum CurrentTrainingLevel {
  ESSENTIAL,
  USING_SPARX_READER,
  COMPLETE,
}

export const useCurrentTrainingProgress = () => {
  const { data: moduleStates } = useCurrentTrainingState({});

  return useMemo(() => {
    const essentialModule = moduleStates?.find(ms => ms.spec.name === TRAINING_MODULE_ESSENTIAL);
    const usingSparxModule = moduleStates?.find(
      ms => ms.spec.name === TRAINING_MODULE_USING_SPARX_READER,
    );
    const completeStepsCount =
      (essentialModule?.steps.filter(step => step.complete).length ?? 0) +
      (usingSparxModule?.steps.filter(step => step.complete).length ?? 0);
    const totalStepsCount =
      (essentialModule?.steps.length ?? 0) + (usingSparxModule?.steps.length ?? 0);
    const progress = totalStepsCount === 0 ? 0 : completeStepsCount / totalStepsCount;
    const currentLevel =
      essentialModule?.complete && usingSparxModule?.complete
        ? CurrentTrainingLevel.COMPLETE
        : essentialModule?.complete
          ? CurrentTrainingLevel.USING_SPARX_READER
          : CurrentTrainingLevel.ESSENTIAL;

    return {
      completeStepsCount,
      totalStepsCount,
      progress,
      currentLevel,
      allComplete: essentialModule?.complete && usingSparxModule?.complete,
      essential: {
        ...essentialModule,
        firstIncompleteIx: essentialModule?.steps.findIndex(s => !s.complete) ?? -1,
      },
      usingSparxReader: {
        ...usingSparxModule,
        firstIncompleteIx: usingSparxModule?.steps.findIndex(s => !s.complete) ?? -1,
      },
    };
  }, [moduleStates]);
};
